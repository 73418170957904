/* font-sizes */

.carousel-next,
.carousel-previous {
  font-size: clamp(3.125rem, 3.5vw, 3.125rem);
  font-family: "Aileron Ultralight", sans-serif;
}

.overlay-text {
  font-size: clamp(1rem, 3vw, 3.5rem);
}

form input,
form textarea {
  font-size: clamp(1.175rem, 3.5vw, 1.375rem);
}

.user-permission-area p {
  font-size: clamp(1rem, 3.5vw, 1.375rem);
}

.title {
  font-size: clamp(2rem, 4vw, 2rem);
}

.popout-exit {
  font-size: clamp(1.375rem, 3.5vw, 1.375rem);
}

.overlay-text,
.title {
  font-family: "Aileron Semibold", sans-serif;
}
/* ContactInfo.js */

.info {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
}

.info-block {
  display: flex;
  align-items: center;
  gap: 30px;
}

.phone,
.address {
  min-height: 50px;
  min-width: 50px;
  margin-left: 10px;
}

.phone {
  background-image: var(--PhoneIcon);
}
.address {
  background-image: var(--AddressIcon);
}

/* shared Carousel code */
.overlay-text,
.ellipse-group-event,
.event-carousel-shade,
.tour-carousel-shade,
.event-carousel-description,
.team-info-button,
.event-info-button {
  position: fixed;
}

.event-carousel-main,
.review-carousel,
.tour-carousel,
.event-carousel,
.employee-container,
.tour-img-container {
  transform: scale(1);
}

.tour-carousel,
.event-carousel {
  align-items: center;
  justify-content: center;
}

.review-carousel,
.tour-carousel,
.event-carousel {
  display: flex;
  padding-bottom: 40px;
}

.carousel-next,
.carousel-previous {
  border: 0;
  background-color: #fff;
  height: 65px;
  width: 80px;
}

.review-carousel .carousel-next {
  right: 0;
}
.review-carousel .carousel-previous {
  left: 0;
}

.review-carousel .carousel-next,
.review-carousel .carousel-previous {
  position: relative;
  top: 170px;
}

.ellipse,
.ellipse-grey {
  width: 10px;
  height: 10px;
  border: none;
  padding: 0;
  border-radius: 100%;
}

.ellipse-grey {
  background-color: #d9d9d9;
}

.ellipse-active {
  background-color: #464646;
  transform: scale(1.3);
}

.ellipse-group-event {
  height: 11%;
  bottom: 0;
  align-items: center;
  z-index: 1;
}

.ellipse-group-event,
.ellipse-group-review,
.ellipse-group-tour {
  display: flex;
  justify-content: space-between;
  width: 20%;
}

/* EventCarousel.js */

.event-carousel,
.tour-carousel {
  width: 80%;
  max-width: 850px;
}

.event-carousel-img,
.tour-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* <alter this so that it follows width better so theres not huge blue space on touring and events */
.tour-img-container,
.event-carousel-main {
  background-color: var(--EventBg);
  width: 100%;
  overflow: hidden;
  max-height: 600px;
  height: 60vw;
}

.overlay-text {
  top: 0;
  margin: 10px 0 0;
  color: #fff;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

.event-info-button {
  background-image: var(--EventInfoIcon);
  background-position: center;
  background-color: rgba(0, 0, 0, 0.01);
  background-repeat: no-repeat;
  border-radius: 100%;
  border: solid #000 3px;
  bottom: 2%;
  right: 1%;
  width: 40px;
  height: 40px;
  margin-right: 5%;
}

.event-carousel-description {
  bottom: 10%;
  line-height: 30px;
  z-index: 1;
}

.event-carousel-description {
  text-align: center;
}

.hidden-text {
  color: rgba(0, 0, 0, 0);
  padding: 0 20px 0 20px;
  text-shadow: none;
}

.event-carousel-shade,
.tour-carousel-shade {
  bottom: 0;
  width: 100%;
  min-height: 35px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
}

.tour-carousel-shade {
  justify-content: center;
  height: 18%;
}

.event-carousel-shade {
  justify-content: end;
  height: 11%;
}

@-webkit-keyframes raiseShade {
  100% {
    -webkit-background-color: rgba(0, 0, 0, 0.7);
    -webkit-transform: scaleY(7);
  }
}

@keyframes raiseShade {
  100% {
    background-color: rgba(0, 0, 0, 0.7);
    transform: scaleY(7);
  }
}

@-webkit-keyframes revealText {
  100% {
    -webkit-color: #fff;
    -webkit-text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  }
}

@keyframes revealText {
  100% {
    color: #fff;
    text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  }
}

.raise-shade {
  animation: raiseShade 1s linear forwards;
}

.show-event-info {
  animation: revealText 1s linear forwards;
}

/* ReviewCarousel.js */
/* .reviewer-stars from ReviewForm.js
they share some comonoalities  */
.review-carousel {
  width: 59vw;
  justify-content: center;
}

.review-stars,
.reviewer-stars {
  display: flex;
  justify-content: center;
}

.review-star,
.reviewer-star-active {
  fill: var(--RatingStar);
}

.review-text {
  width: 100%;
}

.review-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 2;
}

.reviewer-name {
  margin: 0 0 60px 0;
  color: #474747;
}

.review-area {
  width: 80%;
}

/* TouringCarousel.js */

.tour-mini-img {
  width: 15%;
  height: 100px;
  object-fit: cover;
}

.tour-img {
  margin-bottom: 30px;
}

.tour-active {
  border: solid #000 10px;
}

.tour-content-container {
  width: 100%;
}

/* FAQ.js */

.faq-info {
  width: 80%;
}
.faq-info p {
  padding-left: 15%;
}

.faq-info h2 {
  padding-left: 5%;
}

/* commonForm */

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 0;
}

form p {
  margin: 0;
}

form input,
form textarea {
  height: 35px;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
}

.form-input-combo {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 10px;
}

.user-details {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
}

.user-message,
.user-details {
  width: 80%;
}

form .small-line {
  margin-bottom: 45px;
}

form button {
  margin-bottom: 70px;
}

.ask-us form,
.review form {
  margin-bottom: 60px;
}

.form-error-message {
  color: var(--FormErrorColor);
}

.permissions :first-child {
  margin-bottom: 30px;
}

.permissions {
  width: 80%;
}

/* ReviewForm.js */

.user-permission-area {
  display: flex;
}

.user-permission-area input {
  height: 30px;
  width: 30px;
  margin-right: 20px;
}

.user-permission-area p {
  width: 100%;
}

#user-review {
  height: 225px;
}

.user-rating-system {
  display: flex;
  gap: 30px;
}

/* AskForm */

/* TextImgComboGenerator.js */

.img-text-combo-left,
.img-text-combo-right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.highlight-img {
  width: 100%;
  max-width: 450px;
  object-fit: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

.text-container-right,
.text-container-left {
  width: 50%;
  background-color: var(--HighlightsBG);
  position: relative;
  padding: 15px 0 15px 0;
}

.text-container-right p,
.text-container-left p {
  text-align: center;
  padding: 0 10% 0 10%;
}

.text-container-right {
  right: 50px;
}
.text-container-left {
  left: 75px;
}

.title {
  margin-bottom: 0;
}

.role {
  margin: 0;
}

/* page carousel */

.page-carousel {
  display: flex;
  width: 40%;
  justify-content: space-evenly;
}

.page {
  border: 0;
  background-color: #fff;
  color: #808080;
}

.page-active {
  color: #000;
}

/* TeamMembers.js */
.employee-container {
  width: 100%;
  background-position: bottom;
}

.employee-container img {
  width: 100%;
}

.basic-info {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  bottom: 0;
  width: 100%;
  transform: translateY(-3px);
}

.employee-video {
  height: 300px;
  width: 90%;
  background-color: grey;
  margin-bottom: 50px;
}

.employee-popout {
  background-color: white;
  max-width: 700px;
  border: var(--smallBorder);
  border-radius: 10px;
}

.popout-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 90;
}

.popout-exit {
  margin: 30px 30px 0px 0;
  background-color: white;
  border: var(--smallBorder);
  border-radius: 75%;
}

.button-container {
  display: flex;
  justify-content: end;
  width: 100%;
}

.employee-popout p {
  text-align: center;
  padding: 0 15px 0 15px;
}

.basic-info p {
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  color: #fff;
  text-align: center;
}

.employee-title {
  margin: 12px 0 0 0;
}
.employee-name {
  margin: 5px 0 12px 0;
}

.team-info-button {
  width: 35px;
  height: 35px;
  background-image: var(--TeamInfoIcon);
  border-radius: 100%;
  border: solid #000 3px;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.01);
  right: 10%;
  bottom: 30%;
}

.team-info-button:hover,
.team-info-button:focus,
.popout-exit:hover,
.popout-exit:focus,
.event-info-button:hover,
.event-info-button:focus {
  transform: scale(1.2);
  transition-duration: 0.3s;
}

.team-info-button:not(:hover):not(:focus),
.popout-exit:not(:hover):not(:focus),
.event-info-button:not(:hover):not(:focus) {
  transform: scale(1);
  transition-duration: 0.3s;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~  media queries ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
@media screen and (max-width: 1100px) {
  .tour-carousel {
    margin-bottom: 50px;
  }
  .carousel-next,
  .carousel-previous {
    position: fixed;
    bottom: -25px;
    z-index: 1;
  }

  .carousel-next {
    right: 0;
  }

  .carousel-previous {
    left: 0;
  }

  .img-text-combo-left,
  .img-text-combo-right {
    flex-flow: column;
    margin-bottom: -55px;
  }

  .highlights-month:nth-child(3) {
    margin-top: 0;
  }

  .highlight-img {
    width: 60%;
    max-width: 500px;
  }

  .text-container-left,
  .text-container-right {
    padding-top: 70px;
    width: 80%;
    right: 0;
    left: 0;
    top: -100px;
  }

  .img-text-combo-left {
    flex-flow: column-reverse;
  }

  .team-info-button {
    right: 5%;
    height: 30px;
    width: 30px;
  }

  .review-carousel {
    width: 75vw;
  }
}

@media screen and (max-width: 800px) {
  .event-info-button {
    width: 30px;
    height: 30px;
    bottom: 1.5%;
  }
  .review-carousel {
    width: 90vw;
  }
}

@media screen and (max-width: 700px) {
  @-webkit-keyframes raiseShade {
    100% {
      -webkit-background-color: rgba(0, 0, 0, 0.7);
      -webkit-transform: scaleY(10);
    }
  }

  @keyframes raiseShade {
    100% {
      background-color: rgba(0, 0, 0, 0.7);
      transform: scaleY(10);
    }
  }

  .hidden {
    display: none;
  }

  .review-area {
    width: 100%;
  }

  .highlight-img {
    width: 80%;
    max-width: 500px;
  }

  .text-container-left,
  .text-container-right {
    width: 90%;
  }

  .page-carousel {
    width: 80%;
  }

  .user-details {
    grid-template-columns: 1fr;
  }

  .ask-form button {
    margin-bottom: 0;
  }

  .ellipse-group-event,
  .ellipse-group-review,
  .ellipse-group-tour {
    width: 40%;
  }

  .team-info-button {
    right: 10%;
    height: 35px;
    width: 35px;
  }
}

@media screen and (max-width: 500px) {
  .highlight-img {
    width: 90%;
    max-width: 500px;
  }

  .text-container-left,
  .text-container-right {
    width: 100%;
  }

  .page-carousel {
    width: 100%;
  }

  .ellipse,
  .ellipse-grey {
    width: 16px;
    height: 16px;
  }

  .event-info-button {
    height: 25px;
    width: 25px;
  }

  .employee-video {
    height: 225px;
    width: 95%;
    background-color: grey;
    margin-bottom: 20px;
  }

  .popout-exit {
    margin: 15px 15px 0 0;
  }

  .employee-popout p {
    margin-top: 10px;
  }

  .popout-exit {
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info-block {
    gap: 10px;
  }

  .review-carousel .carousel-next,
  .review-carousel .carousel-previous {
    position: fixed;
    top: auto;
  }
}
