@font-face {
  font-family: "Aileron Light Italic";
  src: url("./fonts/aileron.light-italic.otf") format("opentype");
}

@font-face {
  font-family: "Aileron Thin Italic";
  src: url("./fonts/aileron.thin-italic.otf") format("opentype");
}

@font-face {
  font-family: "Aileron Regular";
  src: url("./fonts/aileron.regular.otf") format("opentype");
}

@font-face {
  font-family: "Aileron Ultralight Italic";
  src: url("./fonts/aileron.ultralight-italic.otf") format("opentype");
}

@font-face {
  font-family: "Aileron Semibold Italic";
  src: url("./fonts/aileron.semibold-italic.otf") format("opentype");
}

@font-face {
  font-family: "Aileron Ultralight";
  src: url("./fonts/aileron.ultralight.otf") format("opentype");
}

@font-face {
  font-family: "Aileron Semibold";
  src: url("./fonts/aileron.semibold.otf") format("opentype");
}
