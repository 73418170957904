/* fonts */

:root {
  /* colors */
  --Links: #000;
  --HeaderBg: #ff8a00;
  --InternalLink: #4cc9ff;
  --InternalLinkLight: #7ad5fc;
  --EventBg: #4cc9ff;
  --RatingStar: #ffcd29;
  --HighlightsBG: #4cc9ff;
  --TextBg: #ffcd29;
  --Nav: #fff;
  --LogoBg: #fff;
  --White: #fff;
  --Black: #000;
  --FormErrorColor: #a30000;

  /* visual */
  --BoxShadowUp: 0px -7px 10px 2px rgba(0, 0, 0, 0.2);
  --BoxShadowDown: 0px 7px 10px 2px rgba(0, 0, 0, 0.2);
  --BoxShadowLeftDown: -10px 7px 10px 2px rgba(0, 0, 0, 0.1);
  --BoxShadowUpDown: 0px -7px 10px 2px rgba(0, 0, 0, 0.1),
    0px 7px 10px 2px rgba(0, 0, 0, 0.1);
  --ButtonBoxShadow: -7px 5px 10px 2px rgba(0, 0, 0, 0.1);

  /* background images */
  --HomeHero: url(../data/images/happyOldPeople1.jpg);
  --PhoneIcon: url(../data/logos/phone.svg);
  --AddressIcon: url(../data/logos/address.svg);
  --EmailIcon: url(../data/logos/email.svg);
  --FacebookIcon: url(../data/logos/facebookIcon.jpeg);
  --InstagramIcon: url(../data/logos/instagramIcon.jpeg);
  --YoutubeIcon: url(../data/logos/youtubeIcon.jpeg);
  --TikTokIcon: url(../data/logos/tikTokIcon.svg);
  --TeamInfoIcon: url(../data/logos/info.svg);
  --EventInfoIcon: url(../data/logos/info.svg);
  --MenuBurger: url(../data/logos/hamburger.svg);

  /* borders */
  --smallBorder: solid 1px #000;
  --bigBorder: solid 1px #000;
}
* {
  box-sizing: border-box;
}
/* helps width 100% work the way youd think it would ^*/

body {
  margin: 0;
  padding: 0;
  font-family: "Aileron Regular", sans-serif;
  min-height: 100vh;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

ul,
a {
  list-style-type: none;
  text-decoration: none;
}

a {
  color: var(--Links);
  display: block;
}

.border-line-up,
.border-line-down {
  margin: 50px 0 50px 0;
  border-top: var(--bigBorder);
  width: 100%;
}
.border-line-up {
  box-shadow: var(--BoxShadowUp);
}
.border-line-down {
  box-shadow: var(--BoxShadowDown);
}

.small-line,
.revealed-small-line {
  width: 55%;
  border-bottom: var(--smallBorder);
  margin: 10px 0 80px 0;
}

.revealed-small-line {
  margin: 60px 0 50px 0;
  display: none;
}

/* font-sizes */

p,
label,
li {
  font-size: clamp(1rem, 4vw, 1.625rem);
}

h1 {
  font-size: clamp(1.25rem, 6vw, 2.25rem);
}

h2 {
  font-size: clamp(1.125rem, 5vw, 1.875rem);
}

.internal-link,
.submit-button {
  font-size: clamp(0.875rem, 3.5vw, 1.375rem);
}

.internal-link:hover,
.internal-link:focus,
.submit-button:hover,
.submit-buttonfocus {
  background-color: var(--InternalLinkLight);
}

main {
  min-width: 100%;
}
/* flex column full center eyooo */
.fcfc,
main {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.box-border {
  margin-top: 45px;
  border: var(--bigBorder);
  border-radius: 6px;
  box-shadow: var(--BoxShadowLeftDown);
  width: clamp(1100px, 40vw, 40vw);
}

.box-borderless {
  width: clamp(1100px, 40vw, 40vw);
}

h1 {
  border-bottom: var(--smallBorder);
  padding-bottom: 20px;
  margin-bottom: 40px;
  width: 80%;
  text-align: center;
  font-family: "Aileron Semibold", sans-serif;
}

.internal-link,
.submit-button {
  background-color: var(--InternalLink);
  box-shadow: var(--ButtonBoxShadow);
  border: 0;
  width: 275px;
  height: 50px;
  font-family: "Aileron Semibold", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

button,
span {
  cursor: pointer;
  font-family: "Aileron Regular", sans-serif;
}

@media screen and (max-width: 1100px) {
  .hero-img {
    height: 400px;
  }

  .box-border {
    width: 100%;
    box-shadow: none;
    border: none;
  }

  .box-borderless {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .revealed-small-line {
    display: block;
  }
}

/* must work as low as 320, at lowest widths consider removing content
480 relatievly common */
