/* Hero images */

#home-hero-img {
  background-image: var(--HomeHero);
  background-position: 50% 30%;
}

#about-hero-img {
  background-image: var(--HomeHero);
  background-position: 50% 30%;
}

#ask-hero-img {
  background-image: var(--HomeHero);
  background-position: 50% 30%;
}

#admissions-hero-img {
  background-image: var(--HomeHero);
  background-position: 50% 30%;
}

#careers-hero-img {
  background-image: var(--HomeHero);
  background-position: 50% 30%;
}

#contact-hero-img {
  background-image: var(--HomeHero);
  background-position: 50% 30%;
}

#review-hero-img {
  background-image: var(--HomeHero);
  background-position: 50% 30%;
}

#tour-hero-img {
  background-image: var(--HomeHero);
  background-position: 50% 30%;
}

/* font-sizes */
.row {
  font-size: clamp(1.2rem, 4vw, 1.625rem);
}
/* row needs to be above heading so that heading applies after */

.header-link,
.job-header,
.job-title,
.heading {
  font-size: clamp(2rem, 4vw, 2rem);
}

.info-block {
  font-size: clamp(1.1rem, 4vw, 2rem);
}

.header-text :nth-child(1) {
  font-size: clamp(2.5rem, 6vw, 2.5rem);
}

.page-carousel button {
  font-size: clamp(2rem, 6vw, 2.5rem);
}

.values {
  font-size: clamp(1.875rem, 5vw, 1.875rem);
}

.values li::marker {
  font-size: clamp(3.125rem, 3.5vw, 3.125rem);
}

/* common that i bothered to move over */

.full {
  width: 100%;
}

.hero-img {
  height: 750px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  scale: 1;
}

.hero-title {
  color: #fff;
  filter: drop-shadow(-5px 4px 2px rgba(0, 0, 0, 0.3));
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: clamp(3rem, 10vw, 6rem);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home-hero-title {
  position: fixed;
  max-width: 400px;
}

.normal-hidden,
.medium-hidden {
  display: none;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~  Header.js ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

header {
  width: 100%;
  border-bottom: var(--smallBorder);
  z-index: 10;
  background-color: var(--Nav);
  position: sticky;
  top: 0;
  z-index: 10;
  display: grid;
  align-items: center;
}

.header-bar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding-left: 0;
}

.header-icon {
  width: 225px;
  height: 70px;
  object-fit: contain;
  background-repeat: no-repeat;
}

.header-text :nth-child(1),
.header-text :nth-child(2) {
  margin: 0;
}

.dropdown-menu {
  position: absolute;
  top: 110%;
  padding: 0;
  background-color: #fff;
  display: none;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
}

.dropdown-menu a {
  padding: 10px;
  border-bottom: var(--smallBorder);
  text-align: center;
}

.dropdown li:last-child {
  border-bottom: none;
}

.visible {
  animation: expand 0.1s ease-in-out;
  transform-origin: top;
  display: block;
}

@keyframes expand {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@-webkit-keyframes expand {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

/* Mobile Header */

.mobile-logo,
header {
  height: 90px;
}

.mobile-logo {
  width: 100%;
  max-width: 225px;
}

.mobile-logo-container {
  width: 60%;
  max-width: 225px;
}

.mobile-header {
  display: grid;
  grid-template-columns: 14fr 1fr;
}

.mobile-header img {
  position: relative;
  left: 15%;
}

.mobile-menu {
  display: flex;
  align-items: center;
  padding-right: 30px;
  height: 100%;
  width: 60px;
}

.mobile-menu-toggle {
  border: none;
  background-color: var(--Nav);
  background: var(--MenuBurger) no-repeat;
  width: 100%;
  height: 100%;
  padding: 0;
  background-position: center;
  background-size: contain;
}

.mobile-menu-items {
  background: #fff;
  border: var(--smallBorder);
  padding: 0;
  position: absolute;
  right: 0;
}

.mobile-menu-item {
  margin: 10px;
  text-align: center;
}

.mobile-menu-item:hover {
  background: var(--HighlightDark);
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~  Footer.js ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: auto;
  border-top: var(--bigBorder);
  box-shadow: var(--BoxShadowUp);
  padding: 30px 0px 30px 0px;
}
.footer-logo,
.mobile-logo {
  object-fit: contain;
  background-repeat: no-repeat;
}

.footer-logo {
  max-width: 450px;
}
.footer-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-links {
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-items: center;
}
.img-link {
  height: 70px;
  width: 70px;
}
/* the styling here is also affecting Contact us */

/* ~~~~~~~~~~~~~~~~~~~~~~~~  Home.js ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* welcome */

.welcome p {
  line-height: 2;
  text-align: center;
  width: 80%;
}

.welcome img {
  width: 80%;
}

/* upcoming-events*/

.upcoming-events h1 {
  margin-top: 0;
}

.upcoming-events > .small-line {
  margin: 10px 0 0;
}

/* other-info */

.other-info-section {
  width: 100%;
}

.other-info {
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-left: 0;
}

.other-info-link {
  margin-top: 20px;
}

.other-info-img {
  width: 275px;
  height: 325px;
  object-fit: contain;
}

.other-info-duo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

/* contact-us*/

.contact-us a {
  margin-bottom: 50px;
}

/* home-review */

.home-review,
.home-tour {
  display: flex;
}

.home-tour {
  justify-content: center;
  align-items: center;
}

.home-review {
  gap: 15px;
}

.home-review nav {
  position: relative;
  top: 180px;
}

/* home-tour */
.home-tour-links a {
  margin-bottom: 60px;
}

.home-tour {
  width: 100%;
  gap: 2px;
}

/* home-faq */

.home-faq nav > p {
  text-align: center;
}

.home-faq .small-line {
  margin: 10px 0 40px 0;
}

.home-faq .internal-link:nth-of-type(2) {
  margin-bottom: 50px;
}

.home-faq {
  margin-bottom: 60px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~  AboutUs.js ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.values {
  list-style-type: square;
  padding-left: 60px;
  width: 80%;
}

.values li {
  padding: 0 30px 0 60px;
}

.values p {
  padding-left: 30px;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 30px;
  margin-bottom: 60px;
}

.team-member {
  box-shadow: var(--BoxShadowLeftDown);
  width: 30%;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~  AskUs.js ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* none */
/* ~~~~~~~~~~~~~~~~~~~~~~~~  Careers.js ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.row {
  display: flex;
  justify-content: space-between;
  border-bottom: var(--smallBorder);
  padding: 15px;
}

.cell {
  width: 100%;
}

.row :nth-child(3) {
  text-align: center;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~  ContactUs.js ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* triple link */
.contact-links {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
}

/* socials contact */

.contact-page .info {
  gap: 60px;
}

.info-block a {
  text-decoration: underline;
}

.facebook,
.instagram,
.youtube,
.tiktok {
  height: 50px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.facebook {
  background-image: var(--FacebookIcon);
}

.instagram {
  background-image: var(--InstagramIcon);
}

.youtube {
  background-image: var(--YoutubeIcon);
}

.tiktok {
  background-image: var(--TikTokIcon);
}

.follow-us {
  margin-bottom: 60px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~  Highlights.js ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.page-carousel button {
  max-width: 100px;
  max-height: 100px;
}

.highlights-catalog :nth-child(2) {
  margin-bottom: 15px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~  JobDescription.js ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.job-description li {
  list-style: disc;
}

.synopsis {
  margin-top: 80px;
}

.synopsis p {
  margin: 10px 0 10px 0;
}
.synopsis p:nth-child(1) {
  margin-bottom: 30px;
}

.job-title {
  border-bottom: var(--bigBorder);
}

.job-description {
  align-items: start;
  padding: 0 100px 0 100px;
}

.job-header,
.job-title,
.heading {
  font-family: "Aileron Semibold", sans-serif;
}

.job-description p {
  width: 90%;
}

.job-description nav {
  width: 100%;
}

.job-description a {
  margin-bottom: 50px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~  Review.js ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* ~~~~~~~~~~~~~~~~~~~~~~~~  media queries ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

@media screen and (max-width: 1200px) {
  .header-link {
    font-size: clamp(1.5rem, 28px, 2rem);
  }
}

@media screen and (max-width: 1100px) {
  .hero-img {
    height: 400px;
  }

  .welcome p {
    width: 90%;
  }

  .other-info {
    flex-flow: column;
    gap: 0;
  }

  .other-info-img {
    width: 40%;
    height: auto;
  }

  .other-info-link {
    position: relative;
    max-width: 30%;
    bottom: 85px;
  }

  .home-tour {
    flex-flow: column-reverse;
  }

  .normal-hidden {
    display: flex;
  }

  .footer-logo {
    min-width: 300px;
  }

  .team-member {
    width: 40%;
  }

  .our-team-section {
    width: 90%;
  }

  .contact-links {
    flex-flow: column;
    align-items: center;
  }

  .contact-links a:nth-child(1),
  .contact-links a:nth-child(2) {
    margin-bottom: 30px;
  }

  .home .hero-title {
    background-color: unset;
  }

  .home-review {
    flex-flow: column;
    align-items: center;
  }

  .home-review nav {
    position: static;
  }

  .mobile-hidden {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .medium-hidden {
    display: flex;
  }
  .hidden {
    display: none;
  }

  footer {
    grid-template-columns: none;
    gap: 30px;
  }

  .social-links {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: none;
    gap: 20px;
  }

  .img-link {
    width: 50px;
    height: 50px;
  }

  .values {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    width: 100%;
  }

  .values li {
    padding: 0 30px 0 30px;
  }

  .team-member {
    width: 100%;
  }

  .our-team-section {
    width: 70%;
  }

  .about-us .internal-link:last-of-type {
    margin-bottom: 40px;
  }

  .contact-page .border-line-down,
  .review .border-line-down,
  .tour-now .border-line-down {
    box-shadow: var(--BoxShadowUp);
  }

  .careers {
    margin-bottom: 60px;
  }

  .other-info-img {
    width: 60%;
  }

  .other-info-link {
    position: relative;
    max-width: 40%;
    height: 40px;
    bottom: 85px;
  }

  .row :nth-child(3) {
    display: none;
  }

  .job-description {
    padding: 0 0 0 10px;
  }
}

@media screen and (max-width: 500px) {
  .our-team-section {
    width: 90%;
  }

  .home-hero-title {
    max-width: 300px;
  }
}
